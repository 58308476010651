import { ReactNode, useContext, useState } from "react";
import Toggle from "../components/misc/Toggle";
import {
  BanknotesIcon,
  PencilSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { useNavigate, useLocation } from "react-router-dom";
import { BusinessContext } from "../contexts/BusinessContext";
import CustomLoading from "../components/misc/CustomLoading";
import FormModal from "../components/modals/FormModal";
import LoadingErrorModal from "../components/modals/LoadingErrorModal";
import { ToastContainer, toast } from "react-toastify";
import WarningModal from "../components/modals/WarningModal";
import UsersForm from "../components/business/UsersForm";
import BusinessForm from "../components/business/BusinessForm";
import BillingForm from "../components/business/BillingForm"
import Breadcrumb from "../components/misc/Breadcrumb";

interface PrivateProps {
  children: ReactNode;
  currentLocation: string;
}

const ClientContainer = ({ children, currentLocation }: PrivateProps) => {
  const {
    business,
    isLoading,
    modalWaiting,
    loadingError,
    isFetching,
    checkField,
    updateBusiness,
    deleteBusiness,
    addUser,
    addInvoice, 
    getAllBilling
  } = useContext(BusinessContext);

  //State of Update Business Modal
  const [openEditBusinessModal, setOpenEditBusinessModal] = useState(false);

  //Function to manage Edit Business Modal 
  const switchEditBusinessModal = (state: boolean) => {
    setOpenEditBusinessModal(state);
  };

  //State of Warning Modal
  const [openWarningModal, setOpenWarningModal] = useState(false);

  //Function to manage Warning Modal State
  const switchWarningModal = (state: boolean) => {
    setOpenWarningModal(!state);
  };

  //State of AddUserModal
  const [openUserModal, setOpenUserModal] = useState(false);

  //Function to manage User Modal State
  const switchUserModal = () => {
    setOpenUserModal(!openUserModal);
  };

  //State of Add Billing Modal
  const [openBillingModal, setOpenBillingModal] = useState(false);

  //Function to manage Billing Modal State
  const switchBillingModal = () => {
    setOpenBillingModal(!openBillingModal);
  };

  //Function to manage Active/Inactive Business
  const activeBusiness = () => {
    updateBusiness({ isActive: !business?.isActive });
    setOpenWarningModal(false);
  };

  //Function to addBilling
  const formBillingAction = (data:Record<string, string|number|boolean>) => {
    const callback = () =>{
      setOpenBillingModal(false)
      getAllBilling()      
    }
    addInvoice(business?.id,data, callback)
  }

  if (isLoading) {
    return <CustomLoading />;
  }

  if (loadingError) {
    return <LoadingErrorModal error={loadingError} />;
  }

  return (
    <>
      {openEditBusinessModal && (
        <FormModal
          state={openEditBusinessModal}
          component={
            <BusinessForm
              closeModal={switchEditBusinessModal}
              action={updateBusiness}
              isFetching={isFetching}
              checkField={checkField}
              initialValues={business}
              deleteAction={deleteBusiness}
            />
          }
        />
      )}
      {openWarningModal && (
        <WarningModal
          state={openWarningModal}
          switchState={switchWarningModal}
          item={business?.name}
          actionMessage={business?.isActive ? "desactivará" : "activará"}
          action={activeBusiness}
        />
      )}
      {openUserModal && (
        <FormModal
          state={openUserModal}
          component={
            <UsersForm
              closeModal={switchUserModal}
              action={addUser}
              isFetching={isFetching}
            />
          }
        />
      )}
      {openBillingModal && (
        <FormModal
          state={openBillingModal}
          component={
            <BillingForm
              closeModal={switchBillingModal}
              action={formBillingAction}
              isFetching={modalWaiting}
              plan={business?.subscriptionPlan.name}
            />
          }
        />
      )}
      <div>
        <div className="flex gap-2">
          <Breadcrumb name={business?.name}/>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-orange-600 bg-gray-200 px-3 py-2 text-sm font-medium leading-4 text-orange-600 hover:bg-gray-300 outline-1 outline-orange-700 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:ring-offset-2"
            onClick={() => setOpenEditBusinessModal(true)}
            tabIndex={-1}
          >
            <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        <div className="flex mx-auto justify-end">
          <div className="flex gap-8 h-10">
            {/*Billing Button*/}
            {currentLocation === "facturation" && (
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-orange-600 bg-gray-200 px-3 py-2 text-sm font-medium text-orange-600 hover:bg-gray-300 outline-1 outline-orange-700 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:ring-offset-2"
                onClick={()=>setOpenBillingModal(true)}
              >
                <BanknotesIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                Nuevo Pago
              </button>
            )}
            {/*AddUser Button*/}
            {currentLocation === "users" && (
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-orange-600 bg-gray-200 px-3 py-2 text-sm font-medium text-orange-600 hover:bg-gray-300 outline-1 outline-orange-700 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:ring-offset-2"
                onClick={() => setOpenUserModal(true)}
              >
                <UserPlusIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                Añadir
              </button>
            )}
            <Toggle
              enabled={business?.isActive ?? false}
              setEnabled={switchWarningModal}
              loading={isFetching}
            />
          </div>
        </div>
        <div className="mx-auto">
          {children}
        </div>
      </div>
    </>
  );
};

export default ClientContainer;
