import { useEffect, useState } from "react";
import { InvoiceInterface } from "../../../interfaces/ServerInterfaces";
import moment from "moment";
import CustomLoading from "../../misc/CustomLoading";
import { TrashIcon } from "@heroicons/react/24/outline";

interface BillingInfoProps {
  id: number | null;
  closeModal: Function;
  manageInvoice: {
    get: Function;
    data: InvoiceInterface | null;
    loading: boolean;
    deleteBtn: Function;
  };
}

export default function BillingInfo({
  closeModal,
  id,
  manageInvoice,
}: BillingInfoProps) {
  const { get, data, loading, deleteBtn } = manageInvoice;

  useEffect(() => {
    get(id);    
  }, []);

  if (loading) {
    return (
      <div className="h-96">
        <CustomLoading loading={false} />
      </div>
    );
  }

  return (
    <div className="overflow-y-visible bg-white">
      <div className="px-4 py-5 sm:px-5">
        <h3 className="inline text-lg font-medium leading-6 text-gray-900">
          Factura{" "}
        </h3>
        <button
          type="button"
          className="absolute right-8 top-4 rounded-md border border-transparent bg-red-800 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-red-300 outline-1 focus:outline-none focus:ring-2 focus:ring-red-800 focus:ring-offset-2"
          onClick={() => deleteBtn()}
          tabIndex={-1}
        >
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <span className="inline-flex items-center rounded-md bg-gray-300 px-2.5 py-0.5 text-xl font-medium text-gray-800 ml-3">
          {data?.invoiceNumber}
        </span>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Detalles del Pago.
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">Plan:</dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {data?.subscriptionPlan.name}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">
              Fecha de Registro:
            </dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {moment(data?.createdAt).format("L [->]hh:mm A")}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">
              Registrado por:
            </dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {data?.registeredBy.displayName ? data?.registeredBy.displayName + "(" + data?.registeredBy.username + ")" :
                data?.registeredBy.username ??
                ""}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">
              Total Cobrado:
            </dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {data?.price ? `${data?.price?.amount.toFixed(2)} ${data.price.codeCurrency}` : "$0.00"}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">Descuento:</dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {data?.discount ?? "0"}%
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">
              Activo hasta:
            </dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {moment(data?.nextPayment).format("L")}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">Estado:</dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {data?.status}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 sm:px-6">
            <dt className="text-md font-medium text-gray-600">
              Observaciones:
            </dt>
            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              {data?.observations}
            </dd>
          </div>
        </dl>
      </div>
      <div className="justify-end mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 pt-5 mr-3">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-orange-600 bg-orange-600 px-4 py-2 text-md font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
          onClick={() => closeModal()}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
}
