import { useContext, useEffect, useState } from "react";
import { BusinessContext } from "../../../contexts/BusinessContext";
import CustomLoading from "../../misc/CustomLoading";
import Paginate from "../../misc/Paginate";
import LoadingErrorModal from "../../modals/LoadingErrorModal";
import DeleteModal from "../../modals/DeleteModal";
import InfoModal from "../../modals/InfoModal";
import moment from "moment";
import "moment/locale/es";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import BillingInfo from "./BillingInfo";
import SearchComponent from "../../misc/SearchComponent";

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}

const Billing = () => {
  const {
    getAllBilling,
    getBilling,
    deleteInvoice,
    findInvoice,
    allBilling,
    billing,
    paginate,
    waiting,
    waitingError,
    modalWaiting,
  } = useContext(BusinessContext);

  useEffect(() => {
    getAllBilling();
  }, []);

  //State of Billing Info Modal
  const [billingInfoModal, setBillingInfoModal] = useState<{
    state: boolean;
    id: number | null;
  }>({ state: false, id: null });

  //Function to reset Billing Info Modal State
  const switchBillingInfoModal = () => {
    setBillingInfoModal({ id: null, state: !billingInfoModal.state });
  };

  //State of deleteBilling Modal
  const [openDeleteBillingModal, setOpenDeleteBillingModal] = useState(false);

  //Function to manage deleteBilling Modal
  const switchDeleteBillingModal = () => {
    setOpenDeleteBillingModal(!openDeleteBillingModal);
  };

  //Function to delete Invoice by from main view component
  const deleteInvoiceCallback = (closeModal:Function) =>{
    switchBillingInfoModal();
    deleteInvoice(billing?.id, closeModal)
  }

   //SearchBar State
   const [finding, setFinding] = useState<string | null>(null)

   const setFindingState = (param:string | null) =>{
     setFinding(param);
   }
 
   useEffect(() => {
     finding ? findInvoice(finding) : getAllBilling();
   }, [finding]);

  return (
    <>
      {openDeleteBillingModal && (
        <DeleteModal
          state={openDeleteBillingModal}
          switchState={switchDeleteBillingModal}
          item={billing?.invoiceNumber}
          action={deleteInvoiceCallback}
          isFetching={modalWaiting}
        />
      )}

      {billingInfoModal.state && (
        <InfoModal
          state={billingInfoModal.state}
          component={<BillingInfo closeModal={switchBillingInfoModal} id={billingInfoModal.id} manageInvoice={{get:getBilling,data:billing,loading:modalWaiting, deleteBtn:switchDeleteBillingModal}} />}
        />
      )}

      <div className="mt-5 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-gray-50">
            <div className="py-3">
              <SearchComponent setFindingState={setFindingState} placeholder={"Buscar Factura"} />
              </div>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="">
                  <tr>
                    <th
                      scope="col"
                      className="py-1 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-8"
                    >
                      No. Factura
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-1 text-left text-md font-semibold text-gray-900"
                    >
                      Fecha
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-1 text-left text-md font-semibold text-gray-900"
                    >
                      Monto
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-1 text-left text-md font-semibold text-gray-900"
                    >
                      Plan
                    </th>
                    <th
                      scope="col"
                      className="pl-3 py-1 text-center text-md font-semibold text-gray-900"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {waiting ? (
                    <tr>
                      <td
                        colSpan={5}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-md sm:pl-6"
                      >
                        {" "}
                        <div className="h-96">
                          <CustomLoading loading={false} />
                        </div>
                      </td>
                    </tr>
                  ) : allBilling?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={5}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-md text-center sm:pl-6"
                      >
                        No hay pagos que mostrar
                      </td>
                    </tr>
                  ) : waitingError ? (
                    <LoadingErrorModal error={waitingError} />
                  ) : (
                    allBilling?.map((item) => (
                      <tr
                        key={item.id}
                        className="hover:cursor-pointer"
                        onClick={() =>
                          setBillingInfoModal({ state: true, id: item.id })
                        }
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md sm:pl-6">
                          <div className="whitespace-nowrap px-3 text-md text-gray-500">
                            <div className="text-gray-900 font-bold">
                              {item?.invoiceNumber}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                          <div className="text-gray-900">
                            {moment(item?.createdAt).format("ll")}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                          <div className="text-gray-900">
                            {item.price ? `${item?.price.amount} ${item.price.codeCurrency}` : ""}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                          <div className="text-gray-900">
                            {item?.subscriptionPlan.name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 text-md  text-left font-semibold text-gray-500">
                          <span>
                            {" "}
                            <ChevronRightIcon className="h-5" />{" "}
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Paginate action={getAllBilling} data={paginate} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
