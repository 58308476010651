import { useState, useEffect } from "react";
import { useAppDispatch } from "../store/hooks";
import query from "../services/APIServices";
import { setBusinessCategory, setConfig, setCurrency, setPlans, setUserData, setUserRoles } from "../store/slices/initialSlice";

const useInitialLoad = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const init = async() => {
    Promise.all(
      [
        query.get("/control/billing/subscriptions").then(resp=>resp.data),
        query.get("/control/user").then(resp=>resp.data),
        query.get("/control/businesscategory").then(resp=>resp.data.items),
        query.get("/security/roles").then(resp=>resp.data),
        query.get("/control/currency").then(resp=>resp.data.items),
        query.get("/control/configs").then(resp=>resp.data)
      ]
    ).then(values=>{
        dispatch(setPlans(values[0])); 
        dispatch(setUserData(values[1]));
        dispatch(setBusinessCategory(values[2]));
        dispatch(setUserRoles(values[3]));
        dispatch(setCurrency(values[4]));
        dispatch(setConfig(values[5]))
        setIsLoading(false);
    }).catch((error) => {
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError(
          "Ha ocurrido un error mientras se cargaban los datos ..."
        );
      }
      setIsLoading(false);
    });    
  };

  useEffect(() => {
    init();
  }, []);

  return {
    isLoading,
    error,
  };
};

export default useInitialLoad;
