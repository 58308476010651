import { createSlice } from "@reduxjs/toolkit";
import { PlanInterface, UserInterface, BusinessCategoryInterface, RolesInterface, CurrenciesInterface } from "../../interfaces/ServerInterfaces";

interface dataConfig{
    key:string,
    value:string,
}

interface InitialData{
    userData:UserInterface | null,
    plans:Array<PlanInterface> | null;     
    businessCategory: Array<BusinessCategoryInterface> | null;
    userRoles: Array<RolesInterface> | null,
    currency: Array<CurrenciesInterface> | null
    config: Array<dataConfig> | null
}

const initialState:InitialData = {
    plans:null,
    userData:null, 
    businessCategory:null, 
    userRoles:null,
    currency:null,
    config:null
}

const initialSlice = createSlice({
    name:"initialData",
    initialState,
    reducers:{
        setPlans:(state,action)=>state = {...state, plans:action.payload},
        setUserData:(state,action)=>state = {...state, userData:action.payload}, 
        setBusinessCategory:(state,action)=>state = {...state, businessCategory:action.payload},
        setUserRoles:(state,action)=>state = {...state, userRoles:action.payload},
        setCurrency:(state,action)=>state = {...state, currency:action.payload},
        setConfig:(state,action)=>state = {...state, config:action.payload},   
    }
})

export const {setPlans, setUserData, setBusinessCategory, setUserRoles, setCurrency, setConfig} = initialSlice.actions;
export default initialSlice.reducer;