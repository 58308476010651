import { createContext, ReactNode, useEffect } from "react";
import {
  BusinessInterface,
  ReportsInterface,
  UserInterface,
  InvoiceInterface,
  IncomeInterface,
} from "../interfaces/ServerInterfaces";
import { useServer, PaginateInterface } from "../hooks/useServer";

interface BusinesCtxProps {
  children: ReactNode;
}

interface ContextTypes {
  getAllUsers: Function;
  findUser:Function;
  getAllBilling: Function,
  getUser: Function;
  deleteUser: Function;
  updateUser: Function;
  getBilling: Function;
  addInvoice:Function;
  deleteInvoice:Function;
  checkField: Function;
  updateBusiness: Function;
  deleteBusiness: Function;
  addUser: Function;
  findInvoice:Function;
  resetUserPsw:Function;
  isLoading: boolean;
  loadingError: string | null;
  isFetching: boolean;
  fetchingError: string | null;
  waiting: boolean;
  waitingError: string | null;
  modalWaiting: boolean;
  modalWaitingError: string | null;
  business: BusinessInterface | null;
  reports: ReportsInterface | null;
  paginate: PaginateInterface | null;
  allUsers: UserInterface[] | null;
  user: UserInterface | null;
  allBilling: InvoiceInterface[] | null;
  billing: InvoiceInterface | null,
  incomes:IncomeInterface[] | null
}

const contextVar = {
  getAllUsers: () => null,
  findUser:() => null,
  getUser: () => null,
  updateUser: () => null,
  deleteUser: () => null,
  getBilling: () => null,
  getAllBilling: () => null,
  checkField: () => null,
  updateBusiness: () => null,
  deleteBusiness: () => null,
  addUser: () => null,
  addInvoice:()=> null,
  deleteInvoice:()=>null,
  findInvoice:()=>null,
  resetUserPsw:()=>null,
  isLoading: false,
  loadingError: null,
  isFetching: false,
  fetchingError: null,
  waiting: false,
  waitingError: null,
  modalWaiting: false,
  modalWaitingError: null,
  business: null,
  reports: null,
  paginate: null,
  allUsers: null,
  user: null,
  billing: null,
  allBilling:null,
  incomes:null
};

export const BusinessContext = createContext<ContextTypes>(contextVar);

export const BusinessCtx = ({ children }: BusinesCtxProps) => {
  const {
    loadBusinessData,
    updateBusiness,
    addUser,
    getUser,
    findUser,
    updateUser,
    deleteUser,
    checkField,
    deleteBusiness,
    getAllUsers,
    getBilling,
    getAllBilling,
    addInvoice,
    deleteInvoice,
    findInvoice,
    resetUserPsw,
    isLoading,
    loadingError,
    isFetching,
    fetchingError,
    waiting,
    waitingError,
    modalWaiting,
    modalWaitingError,
    business,
    reports,
    paginate,
    allUsers,
    user,
    billing,
    allBilling, 
    incomes
  } = useServer();

  useEffect(() => {
    loadBusinessData();
  }, []);

  return (
    <BusinessContext.Provider
      value={{
        getAllUsers,
        findUser,
        getAllBilling,
        getUser,
        updateUser,
        deleteUser,
        getBilling,
        checkField,
        updateBusiness,
        deleteBusiness,
        addUser,
        addInvoice,
        deleteInvoice,
        findInvoice,
        resetUserPsw,
        isLoading,
        loadingError,
        isFetching,
        fetchingError,
        waiting,
        waitingError,
        modalWaiting,
        modalWaitingError,
        business,
        reports,
        paginate,
        allUsers,
        user,
        billing,
        allBilling, 
        incomes
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};
