import moment from "moment";
import { useState, useEffect } from "react";
import { useServer } from "../../hooks/useServer";
import GenericTable, { DataTableInterface } from "../misc/GenericTable";
import FormModal from "../modals/FormModal";
import BillingForm from "../business/BillingForm";

const ListBilling = () => {
  const {
    getNextBillingBusiness,
    nextBillingBusiness,
    addInvoice,
    modalWaiting,
    isLoading,
  } = useServer();
  const [openBillingModal, setOpenBillingModal] = useState<{state:boolean, id:number|null}>({state:false,id:null});
  useEffect(() => {
    getNextBillingBusiness();
  }, []);

  //Data to display in table---------------------------------------------------------------------
  const tableTitles = ["Nombre", "Estado", "Tipo", "Licencia válida hasta"];
  const displayData: DataTableInterface[] = [];
  nextBillingBusiness.map((item) => {
    displayData.push({
      rowId: item.id,
      payload: {
        Nombre: item.name,
        Estado: item.status,
        Tipo: item.type,
        "Licencia válida hasta": moment(item.licenceUntil).format(
          "DD [de] MMMM"
        ),
      },
    });
  });

  const rowAction = (id:number) => setOpenBillingModal({state:true, id});
  //---------------------------------------------------------------------------------------------

  //Billing Modal -------------------------------------------------------------------------------
  const formBillingAction = (data:Record<string, string|number|boolean>) => {
    const callback = () => {
      getNextBillingBusiness();
      setOpenBillingModal({state:false, id:null});      
    }
    addInvoice(openBillingModal?.id??0, data, callback)
  }

  //---------------------------------------------------------------------------------------------

  return (
    <div>
      <GenericTable
        tableTitles={tableTitles}
        tableData={displayData}
        loading={isLoading}
        rowAction={rowAction}
      />
      {openBillingModal.state && (
        <FormModal
          state={openBillingModal.state}
          component={
            <BillingForm
              closeModal={setOpenBillingModal}
              action={formBillingAction}
              isFetching={modalWaiting}
              plan={"FREE"}
            />
          }
        />
      )}
    </div>
  );
};

export default ListBilling;
