import { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useController, UseControllerProps } from "react-hook-form";

interface InputProps {
  label?: string;
  placeholder?: string;
  type?: "number" | "text" | "password";
  disabled?:boolean,
}

const Input = (props: UseControllerProps & InputProps) => {
  const { label, placeholder, defaultValue, disabled } = props;
  const { field, fieldState } = useController(props);
  
  const [input, setInput] = useState<string>(field.value||defaultValue||"");
  
  return (
    <div className="py-2">
      {label && (
        <label
          htmlFor={label}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={props.type ?? "text"}
          className={`${
            fieldState.error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
              : "focus:ring-orange-500 border-gray-500 focus:border-orange-600 text-gray-500"
          } block w-full rounded-md sm:text-sm placeholder:text-gray-400`}
          placeholder={placeholder && placeholder}
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            field.onChange(
              props?.type === "number" ? Number(e.target.value) : e.target.value
            );
          }}
          disabled={disabled}
        />

        {fieldState.error && (
          <>
            <div className="pointer-events-none absolute inset-y-0 right-0 top-2 pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>

            <p className="absolute -bottom-5 text-xs text-red-600 flex flex-shrink-0">
              {fieldState.error?.message}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
