import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import AuthRoute from "./AuthRoute";
import AppRoute from "./AppRoute";

export const MainRoute = () => {
  const {isAuth} = useAppSelector((state) => state.auth);

  return (
    <BrowserRouter>
      {isAuth ? <AppRoute /> : <AuthRoute />}
    </BrowserRouter>
  );
};
