import { useState } from "react";
import {
  AllBusinessInterface,
  BusinessInterface,
  NewBusinessFields,
  ReportsInterface,
  UserInterface,
  InvoiceInterface,
  IncomeInterface,
  SumaryInterface,
  TotalSubscripion,
  TotalType,
  PriceInvoiceInterface,
  BillingBusiness,
} from "../interfaces/ServerInterfaces";
import { useAppDispatch } from "../store/hooks";
import { setAuthState } from "../store/slices/authSlice";
import query from "../services/APIServices";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Flip } from "react-toastify";
import { setUserData } from "../store/slices/initialSlice";
import { generateUrlParams } from "../utils/helpers";

export interface PaginateInterface {
  totalPages: number;
  currentPage: number;
  totalItems: number;
}

export interface CheckFieldInterface {
  [field: string]: boolean;
}

export const useServer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [fetchingError, setFetchingError] = useState<string | null>(null);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [waitingError, setWaitingError] = useState<string | null>(null);
  const [modalWaiting, setModalWaiting] = useState<boolean>(false);
  const [modalWaitingError, setModalWaitingError] = useState<string | null>(
    null
  );
  const [paginate, setPaginate] = useState<PaginateInterface|null>(null);
  const [allBusiness, setAllBusiness] = useState<Array<AllBusinessInterface>>(
    []
  );
  const [business, setBusiness] = useState<BusinessInterface | null>(null);
  const [allUsers, setAllUsers] = useState<Array<UserInterface>>([]);
  const [user, setUser] = useState<UserInterface | null>(null);
  const [allBilling, setAllBilling] = useState<Array<InvoiceInterface>>([]);
  const [billing, setBilling] = useState<InvoiceInterface | null>(null);
  const [reports, setReports] = useState<ReportsInterface | null>(null);
  const [incomes, setIncomes] = useState<IncomeInterface[] | null>(null);
  const [sumaryData, setSumaryData] = useState<SumaryInterface | null>(null);
  const [nextBillingBusiness, setNextBillingBusiness] = useState<BillingBusiness[]>([])
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { id } = useParams();

  const manageErrors = (error: any) => {
    if (error.code === "ERR_NETWORK") {
      toast.error("Sin conexión a internet...");
    } else{
      if (error.response?.status === 403) {
        localStorage.removeItem("session");
        dispatch(setAuthState(false));
        window.location.replace("/");
      } else if (error.response?.data?.message) {
        toast.error(error.response?.data.message);
        console.log(error);
      } else {
        toast.error("Ha ocurrido un error mientras se cargaban los datos ...");
      }

    } 
  };

  const logIn = async (username: string, password: string) => {
    setIsFetching(true);
    const dataLogin = { username, password };
    try {
      const login = await query.post("/security/login", dataLogin);
      const respLogin = await login.data;
      localStorage.setItem("session", JSON.stringify(respLogin));
      dispatch(
        setAuthState({
          isAuth: true,
        })
      );
    } catch (e: any) {
      const error =
        e.response?.data.message ?? "Error al conectarse al servidor ...";
      setFetchingError(error);
    }
    setIsFetching(false);
  };

  const logOut = () => {
    query.post("/security/logout", {}).then((data) => {
      if (data.status === 204) {
        localStorage.removeItem("session");
        dispatch(
          setAuthState({
            isAuth: false,
            userName: null,
          })
        );
        redirect("/");
      }
    });
  };

  const getSumaryData = async () => {
    setIsFetching(true);
    await query
      .get("/control/reports/summary")
      .then((resp) => {
        
        const totalBySubscriptionPlan: TotalSubscripion[] = resp.data.totalBySubscriptionPlan.map(
          (item: TotalSubscripion) => {
            switch (item.code) {
              case "CUSTOM":
                return { ...item, code: "PERSONALIZADO" };

              case "STANDARD":
                return { ...item, code: "BÁSICO" };

              case "FREE":
                return { ...item, code: "GRATUITO" };

              case "FULL":
                return { ...item, code: "PROFESIONAL" };

                case "POPULAR":
                  return item;
            }
          }
        );

        const totalByType: TotalType[] = resp.data.totalByType.map(
          (item: TotalType) => {
            switch (item.type) {
              case "RESTAURANT":
                return { ...item, type: "RESTAURANTE" };

              case "SHOP":
                return { ...item, type: "TIENDA" };

              case "DATE":
                return { ...item, type: "CITAS" };
            }
          }
        );        
        setSumaryData({...resp.data,totalBySubscriptionPlan,totalByType});
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          setLoadingError(e.response.data.message);
        } else {
          setLoadingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setIsFetching(false);
  };

  const getAllBusiness = async (filter:Record<string, string|number|boolean>) => {
    setIsLoading(true);
    await query
      .get(
        `/control/business${generateUrlParams(filter)}`
      )
      .then((resp) => {
        setPaginate({
          totalItems: resp.data.totalItems,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
        });
        setAllBusiness(resp.data.items);
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };

  const getBusiness = async () => {
    setIsLoading(true);
    await query
      .get(`/control/business/${id}`)
      .then((resp) => {
        setBusiness(resp.data);
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setLoadingError(error.response.data.message);
        } else {
          setLoadingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setIsLoading(false);
  };

  const findBusiness = async (business: string) => {
    setIsFetching(true);
    await query
      .get(`/control/business?search=${business}&orderBy=createdAt&order=DESC`)
      .then((resp) => {
        setAllBusiness(resp.data.items);
        setPaginate({
          totalItems: resp.data.totalItems,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
        });
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setFetchingError(error.response.data.message);
        } else {
          setFetchingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setIsFetching(false);
  };

  const loadBusinessData = async () => {
    setIsLoading(true);
    await Promise.all([
      query.get(`/control/business/${id}`).then((resp) => resp.data),
      query.get(`/control/reports/general/${id}`).then((resp) => resp.data),
      query.get(`/report/incomes/last-7-days/${id}`).then((resp) => resp.data),
    ])
      .then((values) => {
        setBusiness(values[0]);
        setReports(values[1]);
        const incomes: IncomeInterface[] = values[2].map(
          (item: IncomeInterface) => {
            let day;
            switch (item.day) {
              case "sunday":
                day = "Domingo";
                break;
              case "monday":
                day = "Lunes";
                break;
              case "tuesday":
                day = "Martes";
                break;
              case "wednesday":
                day = "Miércoles";
                break;
              case "thursday":
                day = "Jueves";
                break;
              case "friday":
                day = "Viernes";
                break;
              case "saturday":
                day = "Sábado";
                break;
            }
            return { ...item, day };
          }
        );
        setIncomes(incomes);
      })
      .catch((error) => {
        console.log(error)
        if (error.response?.data?.message) {
          setLoadingError(error.response.data.message);
        } else {
          setLoadingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setIsLoading(false);
  };

  const getReports = async () => {
    setIsLoading(true);
    await query
      .get(`/control/reports/general/${id}`)
      .then((resp) => {
        setReports(resp.data);
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setLoadingError(error.response.data.message);
        } else {
          setLoadingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setIsLoading(false);
  };

  const checkField = async (field: string, data: string) => {
    return query
      .post(`/control/check/${field}`, { [field]: data })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const addBusiness = (data: NewBusinessFields, closeModal: Function) => {
    setIsFetching(true);
    query
      .post("/control/business", data)
      .then((resp) => {
        setIsFetching(false);
        toast.success("Negocio insertado con éxito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        closeModal(false);
        redirect(`${resp.data.id}/details`);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setIsFetching(false);
      });
  };

  const updateBusiness = (
    data: Record<string, string|number|boolean|PriceInvoiceInterface>,
    closeModal?: Function    
  ) => {
    console.log(data)
    setIsFetching(true);
    query
      .patch(`/control/business/${id}`, data)
      .then((resp) => {
        setBusiness(resp.data);
        setIsFetching(false);
        toast.success("Actualización exitosa", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        closeModal && closeModal(false);
        setIsFetching(false);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setIsFetching(false);
      });
  };

  const deleteBusiness = async (closeModal: Function) => {
    setIsFetching(true);
    await query
      .deleteAPI(`/control/business/${id}`, {})
      .then(() => {
        toast.success("Negocio Eliminado con éxito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        closeModal(false);
        setTimeout(() => redirect("/business"), 2500);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
      });
    setIsFetching(false);
  };

  const addUser = (data: Partial<UserInterface>, closeModal: Function) => {
    setIsFetching(true);
    query
      .post("/control/user", { ...data, businessId: Number(id) })
      .then((resp) => {
        getAllUsers();
        setIsFetching(false);
        toast.success("Usuario insertado con éxito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        closeModal(false);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setIsFetching(false);
      });
  };

  const getAllUsers = async (page: number = 1, offset: number = 6) => {
    setWaiting(true);
    await query
      .get(
        `/control/users?orderBy=createdAt&order=DESC&businessId=${id}&per_page=${offset}&page=${page}`
      )
      .then((resp) => {
        const { currentPage, totalItems, totalPages, items } = resp.data;
        setPaginate({ totalItems, totalPages, currentPage });
        setAllUsers(items);
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setWaitingError(error.response.data.message);
        } else {
          setWaitingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setWaiting(false);
  };

  const getUser = async (id: number) => {
    setModalWaiting(true);
    await query
      .get(`/control/user/${id}`)
      .then((resp) => {
        setUser(resp.data);
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setWaitingError(error.response.data.message);
        } else {
          setWaitingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setModalWaiting(false);
  };

  const findUser = async (user: string) => {
    setWaiting(true);
    await query
      .get(
        `/control/users?businessId=${id}&search=${user}&orderBy=createdAt&order=DESC`
      )
      .then((resp) => {
        setAllUsers(resp.data.items);
        setPaginate({
          totalItems: resp.data.totalItems,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
        });
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setWaitingError(error.response.data.message);
        } else {
          setWaitingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setWaiting(false);
  };

  const updateUser = (data: Partial<UserInterface>, closeModal?: Function) => {
    setIsFetching(true);
    query
      .patch(`/control/user/${data.id}`, data)
      .then(async (resp) => {
        await getAllUsers(paginate?.currentPage);
        setUser(resp.data);
        setIsFetching(false);
        toast.success("Actualización exitosa", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        closeModal && closeModal(false);
        setIsFetching(false);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setIsFetching(false);
      });
  };

  const updateMyUser = (
    data: Partial<UserInterface>,
    closeModal?: Function
  ) => {
    setModalWaiting(true);
    const userID = data.id;
    delete data.id;
    query
      .patch(`/control/user/${userID}`, data)
      .then(async (resp) => {
        dispatch(setUserData(resp.data));
        setWaiting(false);
        toast.success("Actualización exitosa", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        closeModal && closeModal(false);
        setModalWaiting(false);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setModalWaiting(false);
      });
  };

  const resetUserPsw = async (email: string) => {
    setIsFetching(true);
    await query
      .post(`/control/user/request-password`, { email })
      .then((resp) => {
        toast.success("Operación completada con éxito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
      });
    setIsFetching(false);
  };

  const deleteUser = async (userId: number, closeModal: Function) => {
    setModalWaiting(true);
    await query
      .deleteAPI(`/control/user/${userId}`, {})
      .then(async () => {
        await getAllUsers(paginate?.currentPage??1);
        toast.success("Usuario Eliminado con éxito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setModalWaiting(false);
        closeModal();
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setModalWaiting(false);
      });
  };

  const getAllBilling = async (page: number = 1, offset: number = 6) => {
    setWaiting(true);
    await query
      .get(`/control/billing/business/${id}?page=${page}&per_page=${offset}`)
      .then((resp) => {
        setAllBilling(resp.data.items);
        setPaginate({
          totalItems: resp.data.totalItems,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
        });
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setWaitingError(error.response.data.message);
        } else {
          setWaitingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setWaiting(false);
  };

  const getBilling = async (invoiceId: number) => {
    setModalWaiting(true);
    await query
      .get(`/control/billing/invoice/${invoiceId}`)
      .then((resp) => {
        let status;
        switch (resp.data.status) {
          case "APPROVED":
            status = "APROBADA";
            break;

          case "REJECTED":
            status = "RECHAZADA";
            break;

          case "PENDING":
            status = "PENDIENTE";
            break;
        }
        setBilling({ ...resp.data, status });
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setWaitingError(error.response.data.message);
        } else {
          setWaitingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setModalWaiting(false);
  };

  const findInvoice = async (invoice: string) => {
    setWaiting(true);
    await query
      .get(
        `/control/billing/business/${id}?search=${invoice}&orderBy=createdAt&order=DESC`
      )
      .then((resp) => {
        setAllBilling(resp.data.items);
        setPaginate({
          totalItems: resp.data.totalItems,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
        });
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setWaitingError(error.response.data.message);
        } else {
          setWaitingError(
            "Ha ocurrido un error mientras se cargaban los datos ..."
          );
        }
      });
    setWaiting(false);
  };

  const addInvoice = async(
    businessId:number,
    data: Partial<InvoiceInterface>,
    closeModal: Function
  ) => {
    setModalWaiting(true);
    await query
      .post(`/control/billing/business/${businessId}`, data)
      .then((resp) => {
        toast.success("Factura agregada con éxito");
        closeModal();
      })
      .catch((e) => manageErrors(e));
      setModalWaiting(false);
  };

  const deleteInvoice = async (invoiceId: number, closeModal: Function) => {
    setModalWaiting(true);
    await query
      .deleteAPI(`/control/billing/invoice/${invoiceId}`, {})
      .then(async () => {
        await getAllBilling(paginate?.currentPage??1);
        toast.success("Factura Eliminada con éxito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setModalWaiting(false);
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setModalWaiting(false);
      });
    closeModal();
  };

  const getNextBillingBusiness = async()=>{
    setIsLoading(true);
    await query.get("/control/billing/next-billing")
    .then(resp=>setNextBillingBusiness(resp.data.items))
    .catch(e=>manageErrors(e));
    setIsLoading(false)
  }

  return {
    sumaryData,
    loadingError,
    fetchingError,
    waitingError,
    modalWaitingError,
    paginate,
    isLoading,
    isFetching,
    waiting,
    modalWaiting,
    allBusiness,
    business,
    reports,
    incomes,
    billing,
    allBilling,
    allUsers,
    user,
    nextBillingBusiness,
    logIn,
    logOut,
    getSumaryData,
    getAllBusiness,
    findBusiness,
    loadBusinessData,
    updateBusiness,
    getAllUsers,
    getReports,
    getBilling,
    getAllBilling,
    getBusiness,
    checkField,
    addBusiness,
    deleteBusiness,
    addUser,
    getUser,
    findUser,
    updateUser,
    updateMyUser,
    deleteUser,
    addInvoice,
    deleteInvoice,
    findInvoice,
    resetUserPsw,
    getNextBillingBusiness
  };
};
