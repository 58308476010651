import { useState, useEffect } from "react";
import { CheckIcon, PencilSquareIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { UserInterface } from "../../../interfaces/ServerInterfaces";
import NoAvatar from "../../misc/NoAvatar";
import FormModal from "../../modals/FormModal";
import moment from "moment";
import "moment/locale/es";
import UserForm from "../UsersForm";
import CustomLoading from "../../misc/CustomLoading";
import LoadingErrorModal from "../../modals/LoadingErrorModal";
import WarningModal from "../../modals/WarningModal";
import { ToastContainer } from "react-toastify";

interface UserInfoInterface {
  id?: number | null;
  closeModal: Function;
  userManage: {
    get: Function;
    upd: Function;
    resetPsw:Function;
    item: UserInterface | null;
  };
  modalsManage: {
    editState: boolean;
    deleteState: boolean;
    switchEditState: Function;
    switchDeleteState: Function;    
    modalLoading: boolean;
    modalLoadingError: string | null;
    isFetching: boolean;
  };
}

export default function UserInfo({
  id,
  closeModal,
  userManage,
  modalsManage,
}: UserInfoInterface) {
  const { item, get, upd, resetPsw } = userManage;
  const {
    editState,
    deleteState,
    switchEditState,
    switchDeleteState,
    modalLoading,
    modalLoadingError,
    isFetching,
  } = modalsManage;

  useEffect(() => {
    get(id);
  }, []);

  //Modal Reset Passw State
  const [resetPswModalState, setResetPswModalState] = useState(false);

  //Function to Manage Modal Reset Passw State
  const switchResetPswModal = () =>{
    setResetPswModalState(!resetPswModalState)
  } 

  //Callback Function to reset password from main Modal
  const resetPaswCallback = () => {
    switchResetPswModal();
    resetPsw(item?.email)
  }

  if (modalLoading) {
    return (
      <div className="h-96">
        <CustomLoading loading={false} />
      </div>
    );
  }

  if (modalLoadingError) {
    <LoadingErrorModal error={modalLoadingError} />;
  }

  return (
    <>
      {editState && (
        <FormModal
          state={editState}
          component={
            <UserForm
              closeModal={switchEditState}
              isFetching={isFetching}
              action={upd}
              initialValues={item}
              deleteModalManage={{
                state: deleteState,
                switchState: switchDeleteState,
              }}
            />
          }
        />
      )}

      {resetPsw && (
        <WarningModal state={resetPswModalState} switchState={switchResetPswModal} actionMessage="restablecerá la contraseña del usuario" item={item?.username} action={resetPaswCallback} />
      )}

      
      <div className="flex h-full">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
            {/* Profile header */}
            <div>            
              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <div className="mt-3 sm:flex sm:items-end sm:space-x-5 ">
                  {item?.avatar ? (
                    <div className="flex">
                      <img
                        className="flex h-14 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                        src={item.avatar.src}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="md:h-32 md:w-32 h-24 w-24 mx-auto">
                      <NoAvatar />
                    </div>
                  )}

                  <div className="relative sm:-top-8 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div className="mt-6 min-w-0 flex-1 sm:hidden xl:block">
                      <h1 className="truncate text-2xl font-bold text-gray-900">
                        {item?.displayName}
                      </h1>
                    </div>
                    <div className="justify-end mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 flex-shrink">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-orange-600 bg-white px-4 py-2 text-sm font-medium text-orange-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
                        tabIndex={-1}
                        onClick={() => switchEditState()}
                      >
                        <PencilSquareIcon
                          className="-ml-1 mr-2 h-5 w-5 text-orange-400"
                          aria-hidden="true"
                        />
                        <span>Editar</span>
                      </button>

                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-red-700 bg-white px-4 py-2 text-sm font-medium text-red-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                        tabIndex={-1}
                        onClick={() => switchResetPswModal()}
                        disabled={isFetching}
                      >
                        <ArrowPathIcon
                          className={`-ml-1 mr-2 h-5 w-5 text-red-700 ${isFetching && "animate-spin"}`}
                          aria-hidden="true"
                        />
                        <span>Restablecer Contraseña</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-6" />

            {/* Description list */}
            <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-md font-medium text-gray-500">
                    UserName
                  </dt>
                  <dd className="mt-1 text-md text-gray-900">
                    {item?.username}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-md font-medium text-gray-500">
                    Correo Electrónico
                  </dt>
                  <dd className="mt-1 text-md text-gray-900">{item?.email}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-md font-medium text-gray-500">
                    Fecha Creado
                  </dt>
                  <dd className="mt-1 text-md text-gray-900">
                    {moment(item?.createdAt).format("ll [->] hh:mm A")}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-md font-medium text-gray-500">Roles</dt>
                  {item?.roles.map((rol) => (
                    <dd key={rol.code} className="mt-1 text-md text-gray-900">
                      <span className="inline-flex gap-1 items-center">
                        <CheckIcon className="h-3" />
                        {rol.name}
                      </span>
                    </dd>
                  ))}
                </div>
              </dl>
            </div>
          </main>
        </div>
      </div>
      <div className="justify-end mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 pt-5">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-orange-600 bg-orange-600 px-4 py-2 text-md font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
          onClick={() => closeModal(false)}
        >
          Aceptar
        </button>
      </div>
    </>
  );
}
