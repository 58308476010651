import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/business/BusinessNavbar";
import { BusinessCtx } from "../contexts/BusinessContext";
import ClientContainer from "../containers/ClientContainer";

const Client = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[3];

  return (
    <BusinessCtx>
      <ClientContainer currentLocation={currentLocation}>
        <Navbar currentLocation={currentLocation} />
        <Outlet />
      </ClientContainer>
    </BusinessCtx>
  );
};

export default Client;
