import { createSlice } from "@reduxjs/toolkit";

const session = localStorage.getItem("session");

interface AuthData{
  isAuth:boolean;
}

const initialState:AuthData = {
  isAuth:session ? true : false
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState:(state,action)=>state=action.payload
  },
});

export const { setAuthState } = authSlice.actions;

export default authSlice.reducer;
