import { useContext, useEffect, useState } from "react";
import { BusinessContext } from "../../../contexts/BusinessContext";
import CustomLoading from "../../misc/CustomLoading";
import Paginate from "../../misc/Paginate";
import LoadingErrorModal from "../../modals/LoadingErrorModal";
import NoAvatar from "../../misc/NoAvatar";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import InfoModal from "../../modals/InfoModal";
import UserInfo from "./UserInfo";
import DeleteModal from "../../modals/DeleteModal";
import SearchComponent from "../../misc/SearchComponent";


function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}

const Users = () => {
  const {
    getAllUsers,
    allUsers,
    getUser,
    user,
    updateUser,
    findUser,
    deleteUser,
    resetUserPsw,
    paginate,
    waiting,
    waitingError,
    modalWaiting,
    modalWaitingError,
    isFetching,
  } = useContext(BusinessContext);

  //State of User Info Modal
  const [userInfoModal, setUserInfoModal] = useState<{
    state: boolean;
    id: number | null;
  }>({ state: false, id: null });

  //Function to reset User Info Modal State
  const switchUserInfoModal = () => {
    setUserInfoModal({ id: null, state: !userInfoModal.state });
  };

  //State of Update Business Modal
  const [openEditUserModal, setOpenEditUserModal] = useState(false);

  //Function to manage Edit User Modal
  const switchEditUserModal = () => {
    setOpenEditUserModal(!openEditUserModal);
  };

  //State of deleteUser Modal
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);

  //Function to manage deleteUser Modal
  const switchDeleteUserModal = () => {
    setOpenDeleteUserModal(!openDeleteUserModal);
  };

  // Callback for delete modal
  const deleteCallback = (callback: Function) => {
    const id = userInfoModal.id;
    setUserInfoModal({ state: false, id: null });
    setOpenEditUserModal(false);
    deleteUser(id, callback);
  };

  //SearchBar State
  const [finding, setFinding] = useState<string | null>(null)

  const setFindingState = (param:string | null) =>{
    setFinding(param);
  }

  useEffect(() => {
    finding ? findUser(finding) : getAllUsers();
  }, [finding]);

  return (
    <>
      {openDeleteUserModal && (
        <DeleteModal
          state={openDeleteUserModal}
          switchState={switchDeleteUserModal}
          item={user?.username}
          action={deleteCallback}
          isFetching={modalWaiting}
        />
      )}

      {userInfoModal.state && (
        <InfoModal
          state={userInfoModal.state}
          component={
            <UserInfo
              closeModal={switchUserInfoModal}
              id={userInfoModal.id}
              userManage={{
                get: getUser,
                upd: updateUser,
                item: user,
                resetPsw: resetUserPsw
              }}
              modalsManage={{
                editState: openEditUserModal,
                deleteState: openDeleteUserModal,
                switchEditState: switchEditUserModal,
                switchDeleteState: switchDeleteUserModal,
                modalLoading: modalWaiting,
                modalLoadingError: modalWaitingError,
                isFetching,
              }}
            />
          }
        />
      )}      
      <div className="mt-5 flex flex-col">      
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">        
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">            
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-gray-50">
              <div className="py-3">
              <SearchComponent setFindingState={setFindingState} placeholder={"Buscar Usuario"} />
              </div>
            
              <table className="min-w-full divide-y divide-gray-300">              
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-1 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-20"
                    >
                      Usuarios
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-1 text-left text-md font-semibold text-gray-900"
                    >
                      Roles
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-1 text-left text-md font-semibold text-gray-900"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      className="pl-3 py-1 text-center text-md font-semibold text-gray-900"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {waiting ? (
                    <tr>
                      <td
                        colSpan={4}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-md sm:pl-6"
                      >
                        {" "}
                        <div className="h-96">
                          <CustomLoading loading={false} />
                        </div>
                      </td>
                    </tr>
                  ) : allUsers?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={4}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-md text-center sm:pl-6"
                      >
                        No hay usuarios que mostrar
                      </td>
                    </tr>
                  ) : waitingError ? (
                    <LoadingErrorModal error={modalWaitingError} />
                  ) : (
                    allUsers?.map((person) => (
                      <tr
                        key={person.id}
                        className="hover:cursor-pointer"
                        onClick={() =>
                          setUserInfoModal({ state: true, id: person?.id })
                        }
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              {person?.avatar?.src ? (
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={person.avatar.src}
                                  alt=""
                                />
                              ) : (
                                <NoAvatar />
                              )}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person?.displayName}
                              </div>
                              <div className="text-gray-500">
                                {person?.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                          {person?.roles.map((rol) => (
                            <div key={rol.code} className="text-gray-900">
                              {rol.name}
                            </div>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                          <span
                            className={classNames(
                              person?.isActive
                                ? "bg-green-100 text-green-800"
                                : "bg-red-100 text-red-800",
                              "inline-flex rounded-full  px-2 text-md font-semibold leading-5 "
                            )}
                          >
                            {person?.isActive ? "Activo" : "Inactivo"}
                          </span>
                        </td>
                        <td className="whitespace-nowrap py-4 text-md  text-left font-semibold text-gray-500">
                          <span>
                            {" "}
                            <ChevronRightIcon className="h-5" />{" "}
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Paginate action={getAllUsers} data={paginate} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
