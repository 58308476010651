import {useState} from "react";
import Sidebar from "../components/Sidebar";
import AppContainer from "../containers/AppContainer";
import { useServer } from "../hooks/useServer";
import CustomLoading from "../components/misc/CustomLoading";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {isLoading} = useServer();

  const switchSidebar = (action:boolean):void => {
    setSidebarOpen(action);
  }

  return (
    isLoading
    ?(<CustomLoading />)
    :(
      <>
      <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={true}
          theme="light"
          
        />
      <Sidebar state={sidebarOpen} switchSidebar={switchSidebar} />
      <AppContainer switchSidebar={switchSidebar} /> 
    </>

    )
    
  );
};

export default App;

