import { useState } from "react";
import { Listbox } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { useController, UseControllerProps } from "react-hook-form";
import { RolesInterface } from "../../interfaces/ServerInterfaces";

interface MultiselectInterface {
  data: Array<Partial<RolesInterface>>| null;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Multiselect = (props: UseControllerProps & MultiselectInterface) => {
  const { field, fieldState } = useController(props);
  const { data } = props;
  const [selectedItems, setSelectedItems] = useState<Array<RolesInterface>>(field.value);

  return (
    <Listbox
      value={selectedItems}
      onChange={(e) => {
        setSelectedItems(e);
        field.onChange(e);
      }
    }
      by={"code"}
      multiple
    >
      <Listbox.Label className="block text-sm font-medium text-gray-700 first-letter:uppercase">
        {props.name}
      </Listbox.Label>
      <Listbox.Button
        {...field}
        className={`relative w-full mt-1 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm active:border-orange-500  active:ring-orange-600 sm:text-sm ${
          fieldState.error && "text-red-700 border-red-600 border-2"
        }`}
        tabIndex={-1}
      >
        {selectedItems.length !== 0
          ? selectedItems?.map(
              (person) =>
                person.name !== "" && (
                  <span
                    key={person.code}
                    className="inline-block border border-gray-300 m-1 px-1 font-semibold"
                  >
                    {person.name}
                  </span>
                )
            )
          : "Seleccione ..."}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Listbox.Options className="absolute z-20 mt-1 max-h-48 w-full overflow-auto rounded-md cursor-pointer bg-white py-1 text-base shadow-lg ring-1 ring-gray-700 ring-opacity-5 focus:outline-none sm:text-sm">
        {data?.map((item) => {
          /*User is admin*/
          const caseDisabled1 = item.code !=="ADMIN" && selectedItems.filter(item=>item.code==="ADMIN").length!==0;

          /*User is owner*/
          const caseDisabled2 = item.code !=="OWNER" && selectedItems.filter(item=>item.code==="OWNER").length!==0

          /*User is not admin either owner*/
          const caseDisabled3 = (item.code ==="OWNER" || item.code ==="ADMIN") && selectedItems.filter(item=>item.code !=="OWNER"&& item.code !=="ADMIN").length!==0;          

          return(
           (caseDisabled1 || caseDisabled2 || caseDisabled3) ||  
          <Listbox.Option
            key={item.code}
            value={item}
            className={({ active }) =>
              classNames(
                active ? "text-white bg-orange-600" : "text-gray-900",
                "relative cursor-default select-none py-2 pl-3 pr-9"
              )
            }
          >
            {({ selected, active }) => (
              <>
                <span
                  className={classNames(
                    selected ? "font-semibold" : "font-normal",
                    "block truncate"
                  )}
                >
                  {item.name}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      active ? "font-semibold" : "text-black",
                      "absolute inset-y-0 right-0 flex items-center pr-4"
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}

              </>
            )}
          </Listbox.Option>
        )})}
      </Listbox.Options>
      {fieldState.error && (
        <>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
          <span className="text-xs text-red-600">
            {fieldState.error.message}
          </span>
        </>
      )}
    </Listbox>
  );
};

export default Multiselect;
