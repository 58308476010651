import { Route, Routes } from "react-router-dom";
import App from "../pages/AppPage";
import Config from "../pages/ConfigPage";
import Business from "../pages/BusinessPage";
import Client from "../pages/ClientPage";
import Details from "../components/business/businessDetails/Details";
import Resume from "../components/business/businessDetails/Resume";
import Billing from "../components/business/businessDetails/Billing";
import Users from "../components/business/businessDetails/Users";
import NotFoundpage from "../pages/NotFoundPage";
import Dashboard from "../pages/DashboardPage";
import useInitialLoad from "../hooks/useInitialLoad";
import CustomLoading from "../components/misc/CustomLoading";
import LoadingErrorModal from "../components/modals/LoadingErrorModal";
import 'react-toastify/dist/ReactToastify.css';
import ListBilling from "../components/billing/ListBilling";


const AppRoute = () => {
  const { isLoading, error } = useInitialLoad();


  return (
    <>
      {isLoading ? (
        <CustomLoading />
      ) 
      : (
        <Routes>          
          <Route path="/" element={<App />}>
            <Route index element={<Dashboard />} />
            <Route path="business">
              <Route index element={<Business />} />
              <Route path=":id" element={<Client />}>
                <Route index path="details" element={<Details />} />
                <Route path="resume" element={<Resume />} />
                <Route path="facturation" element={<Billing />} />
                <Route path="users" element={<Users />} />
              </Route>
            </Route> 

            <Route path="billing" element={<ListBilling />}></Route>

            <Route index element={<Config />}></Route>
          </Route>
          <Route path="/*" element={<NotFoundpage />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoute;
