import { useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  ArrowDownOnSquareStackIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  BusinessInterface,
  NewBusinessFields,
  PriceInvoiceInterface,
} from "../../interfaces/ServerInterfaces";
import DeleteModal from "../modals/DeleteModal";
import LoadingSpin from "../misc/LoadingSpin";
import Input from "../forms/Input";
import Select from "../forms/Select";
import CurrencyInput from "../forms/CurrencyInput";

interface BusinessFormProps {
  closeModal: Function;
  action: Function;
  isFetching: boolean;
  checkField: Function;
  initialValues?: BusinessInterface | null;
  deleteAction?: Function;
}

export default function BusinessForm({
  closeModal,
  action,
  isFetching,
  checkField,
  initialValues,
  deleteAction,
}: BusinessFormProps) {
  const { plans, businessCategory, config } = useAppSelector(
    (state) => state.initial
  );

  console.log(initialValues)

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    unregister,
     formState: { errors, isSubmitting, defaultValues, dirtyFields },
  } = useForm<Record<string, string | number | boolean | PriceInvoiceInterface| null>>({
    mode:"onTouched",
    defaultValues: {
      name: initialValues?.name ?? "",
      dni: initialValues?.dni ?? "",
      slug: initialValues?.slug ?? "",
      type: initialValues?.type,
      businessCategoryId: initialValues?.businessCategory.id,
      subscriptionPlanId: initialValues?.subscriptionPlanId,
    },
  });

  const onSubmit: SubmitHandler<
    Record<string, string | number | boolean | PriceInvoiceInterface| null>
  > = (data, e) => {
    action(data,closeModal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const openModalDelete = (state: boolean) => {
    setDeleteModal(state);
  };

  const plan = watch("subscriptionPlanId")?? getValues("subscriptionPlanId");
  useEffect(() => {
    if(plan !== 5) unregister("subscriptionPlanPrice")   
  }, [plan])
  

  const businessTypes = [
    { id: "SHOP", name: "Tienda" },
    { id: "RESTAURANT", name: "Restaurante" },
    { id: "DATE", name: "Citas" },
  ];

  const paymentCurrencies =
    config
      ?.find((item) => item.key === "payment_currency_enabled")
      ?.value.split(",") || [];

  return (
    <>
      <div className="flex justify-between md:justify-center mb-4">
        <h3 className="md:text-lg text-md font-medium leading-6 text-gray-900">
          {initialValues ? `Editar ${initialValues.name}` : "Nuevo Negocio"}
        </h3>
        {initialValues && (
          <>
            {deleteModal && (
              <DeleteModal
                state={deleteModal}
                switchState={openModalDelete}
                item={initialValues.name}
                action={deleteAction}
                isFetching={isFetching}
              />
            )}
            <button
              type="button"
              className="absolute right-8 top-4 rounded-md border border-transparent bg-red-800 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-red-300 outline-1 focus:outline-none focus:ring-2 focus:ring-red-800 focus:ring-offset-2"
              onClick={() => setDeleteModal(true)}
              tabIndex={-1}
            >
              <TrashIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </>
        )}
      </div>

      <form
        className="space-y-8 divide-y divide-gray-300"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-1 sm:space-y-5">
          <div className="pt-2">
            <div className="mt-6 flex flex-col gap-y-6 gap-x-2">
              {/*NAME */}
              <Input
                name="name"
                control={control}
                label={"Nombre"}
                placeholder="Nombre del negocio"
                rules={{ required: "Este campo es requerido" }}
              />
              {/*BUSINESSCATEGORYID */}
              <Select
                name="businessCategoryId"
                label="Categoría"
                control={control}
                data={
                  businessCategory?.map((itm) => ({
                    id: itm.id,
                    name: itm.name,
                  })) ?? []
                }
                rules={{ required: "Campo requerido" }}
              />

              {/*DNI */}
              <Input
                name="dni"
                control={control}
                label="DNI"
                rules={{
                  required: "Campo requerido",
                  validate: {
                    check: async (value) => {
                      const check =
                        value === defaultValues?.dni ||
                        (await checkField("dni", value));
                      return check || "DNI Existente";
                    },
                  },
                }}
              />

              {/*TYPE */}
              <Select
                data={businessTypes}
                label="Tipo"
                name="type"
                control={control}
                rules={{ required: "Campo requerido" }}
                defaultValue={initialValues?.type}
              />

              {/*SLUG */}
              <Input
                name="slug"
                control={control}
                label="Slug"
                rules={{
                  required: "Campo requerido",
                  validate: {
                    check: async (value) => {
                      const check =
                        value === defaultValues?.slug ||
                        (await checkField("slug", value));
                      return check || "SLUG Existente";
                    },
                  },
                }}
              />

              {/*SUBSCRIPTIONPLANID */}
              <Select
                data={
                  plans?.map((item) => ({ id: item.id, name: item.name })) ?? []
                }
                label="Plan"
                name="subscriptionPlanId"
                control={control}
                rules={{ required: "Campo requerido" }}
              />

              {plan === 5 && (
                <CurrencyInput
                  currencies={paymentCurrencies}
                  label="Precio"
                  name="subscriptionPlanPrice"
                  control={control}
                  rules={{required:"Campo requerido"}}
                  defaultValue={initialValues?.subscriptionPlanPrice}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
              onClick={() => closeModal(false)}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="relative ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 pl-8 pr-2 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:bg-secondary"
              disabled={
                isSubmitting ||
                isFetching ||
                Object.entries(dirtyFields).length === 0
              }
            >
              {isSubmitting || isFetching ? (
                <div className="absolute left-2">
                  <LoadingSpin />
                </div>
              ) : initialValues ? (
                <PencilIcon className="absolute left-2 w-5 h-5" />
              ) : (
                <ArrowDownOnSquareStackIcon className="absolute left-2 w-5 h-5" />
              )}
              <span className="ml-1">
                {isSubmitting || isFetching
                  ? "Enviando..."
                  : initialValues
                  ? "Guardar"
                  : "Insertar"}
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
