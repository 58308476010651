import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { BaseSyntheticEvent } from "react";

interface SearchProps {
  setFindingState: Function;
  placeholder?:string
}

const SearchComponent = ({ setFindingState, placeholder }: SearchProps) => {
  const findFunction = (e: BaseSyntheticEvent) => {
    if (e.target.value !== "") {
      setFindingState(e.target.value);

    } else {
      setFindingState(null);
    }
  };

  return (
    <div className="mx-auto w-full max-w-xl bg-inherit">
      <label htmlFor="search" className="sr-only">
        Buscar
      </label>
      <div className="relative flex text-gray-400">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="search"
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-400 focus:border-transparent focus:bg-opacity-100 focus:text-gray-900 focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder={placeholder}
          type="search"
          name="search"
          onChange={findFunction}
        />
      </div>
    </div>
  );
};

export default SearchComponent;
