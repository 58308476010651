/*
 * Los datos de la tabla (tablaData) debe contener como primer elemento el id del item para identificar el elemento cuando se de click sobre la fila
 *Los titles deben coincidir con los índices en los datos pasados para el body
 */

import {ChevronRightIcon} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { BaseSyntheticEvent } from "react";
import { FunnelIcon as Active } from "@heroicons/react/20/solid";
import { FunnelIcon as Inactive } from "@heroicons/react/24/outline";
import SpinnerLoading from "./SpinnerLoading";
import EmptyList from "./EmptyList";
import MultipleActBtn, { ItemsMultiActionBtn } from "./MultipleActBtn";

interface SearchingInterface {
  action: Function; //Callback Function to control Searching action
  placeholder?: string;
}

interface FilterInterface {
  action: Function;
  state: boolean;
}

interface ActionsInterface {
  action: Function;
  icon?: React.ReactNode;
}

export interface DataTableInterface {
  rowId?: string | number;
  deletedRow?: boolean;
  boldRow?: boolean;
  payload: Record<string, string | number | React.ReactNode>;
}

interface GenericTableProps {
  tableTitles: string[];
  tableData: Array<DataTableInterface>;
  rowAction?: Function; //Callback function to control click on each row. It work with id param
  tableActions?: ActionsInterface | ActionsInterface[];
  searching?: SearchingInterface;
  filter?: FilterInterface;
  loading?: boolean;
  paginateComponent?: React.ReactNode;
  actions?: ItemsMultiActionBtn[];
}

export default function GenericTable({
  tableTitles,
  tableData,
  rowAction,
  tableActions,
  searching,
  filter,
  loading,
  paginateComponent,
  actions,
}: GenericTableProps) {
  const [timeOutId, setTimeOutId] = useState<number | undefined>(undefined);

  //Debounce for filter -----------------------------------------------------------------------------
  const onKeyDown = () => {
    clearTimeout(timeOutId);
  };

  const onKeyUp = (e: BaseSyntheticEvent) => {
    const time = Number(
      setTimeout(() => {
        if (e.target.value !== "") {
          searching?.action(e.target.value);
        } else {
          searching?.action(null);
        }
      }, 800)
    );
    setTimeOutId(Number(time));
  };

  const [btnSearch, setBtnSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  useEffect(() => {
    if (searchValue === null && btnSearch) setBtnSearch(false);
  }, [searchValue]);

  //-------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="flex flex-col">
        <div className="">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {(searching || filter || tableActions || actions) && (
                <div
                  className={`inline-flex w-full gap-3 items-center px-5 py-3 ${!searching ? "justify-end" : ""}`}
                >
                  {searching && (
                    <>
                      <div
                        className={`flex w-full bg-inherit ${
                          !filter && "m-auto"
                        }`}
                      >
                        <div className="relative w-full text-gray-400">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            id="search"
                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-400 focus:border-transparent focus:bg-opacity-100 focus:text-gray-900 focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
                            placeholder={searching?.placeholder}
                            type="text"
                            name="search"
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onMouseOut={() =>
                              searchValue === "" && setSearchValue(null)
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {filter && (
                    <button
                      className="flex item cursor-pointer"
                      onClick={() => filter.action()}
                    >
                      {filter.state ? (
                        <Active className="h-6 active:shadow-inner cursor-pointer text-slate-700" />
                      ) : (
                        <Inactive className="h-6 active:shadow-inner text-slate-700" />
                      )}
                    </button>
                  )}

                  {tableActions && (
                    <>
                      {Array.isArray(tableActions) ? (
                        tableActions.map((item, index) => (
                          <div
                            key={index}
                            className="flex item cursor-pointer"
                            onClick={() => item.action && item.action()}
                          >
                            {item.icon}
                          </div>
                        ))
                      ) : (
                        <div
                          className="flex item cursor-pointer"
                          onClick={() =>
                            tableActions.action && tableActions.action()
                          }
                        >
                          {tableActions.icon}
                        </div>
                      )}
                    </>
                  )}

                  {actions && <MultipleActBtn items={actions} />}
                </div>
              )}

              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 first-letter:uppercase"
                    >
                      {tableTitles[0]}
                    </th>
                    {tableTitles.slice(1).map((title, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 first-letter:uppercase"
                      >
                        {title}
                      </th>
                    ))}

                    {rowAction && (
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 first-letter:uppercase"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {loading && loading === true ? (
                    <tr>
                      <td
                        colSpan={tableTitles.length + 1}
                        className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 txt-center"
                      >
                        <SpinnerLoading />
                      </td>
                    </tr>
                  ) : tableData.length !== 0 ? (
                    tableData.map((info, key) => (
                      <tr
                        onClick={() => rowAction && rowAction(info.rowId)}
                        key={key}
                        className={`${key % 2 === 0 ? "" : "bg-gray-100"} ${
                          rowAction ? "cursor-pointer" : ""
                        } ${info?.deletedRow ? "line-through" : ""} ${
                          info.boldRow ? "font-bold" : ""
                        }`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {info.payload[tableTitles[0]]}
                        </td>
                        {tableTitles.slice(1).map((item, index) => (
                          <td
                            key={index}
                            className="relative whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          >
                            {info.payload[item]}
                          </td>
                        ))}
                        {rowAction && (
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="#"
                              className="flex justify-end text-slate-600 hover:text-indigo-900"
                            >
                              <ChevronRightIcon className="h-5" />
                            </a>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={tableTitles.length + 1}
                        className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 txt-center"
                      >
                        <EmptyList />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {paginateComponent && paginateComponent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
