export const generateUrlParams = (
    options?: Record<string, string | number | boolean | null>
) => {
    let list: string[] = [];
    if (options) {
        for (const [key, value] of Object.entries(options)) {
            if (!value) continue;
            list.push(`${key}=${value}`);
        }
    }
    if (list.length !== 0) {
        return "?" + list.join("&");
    } else {
        return "";
    }
};