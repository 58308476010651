import { useEffect, useState } from "react";
import { useServer } from "../hooks/useServer";
import LoadingErrorModal from "../components/modals/LoadingErrorModal";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ToastContainer } from "react-toastify";
import FormModal from "../components/modals/FormModal";
import BusinessForm from "../components/business/BusinessForm";
import SearchComponent from "../components/misc/SearchComponent";
import Paginate from "../components/misc/Paginate";
import GenericTable, {
  DataTableInterface,
} from "../components/misc/GenericTable";
import { ItemsMultiActionBtn } from "../components/misc/MultipleActBtn";
import { FolderPlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const Business = () => {
  const redirect = useNavigate();
  const {
    getAllBusiness,
    addBusiness,
    checkField,
    isLoading,
    isFetching,
    allBusiness,
    paginate,
  } = useServer();

  const [modalState, setModalState] = useState(false);
  const [finding, setFinding] = useState<string | null>(null);
  const [filter, setFilter] = useState<
    Record<string, string | number | boolean>
  >({ page: 1 });

  const switchModalState = (state: boolean) => {
    setModalState(state);
  };

  useEffect(() => {
    getAllBusiness(filter);
  }, [filter]);

  //Data to display in table ------------------------------------------------------
  const tableTitles = ["Negocio", "Plan", "Estado"];
  const tableData: DataTableInterface[] = [];
  allBusiness.map((item) => {
    tableData.push({
      rowId: item.id,
      payload: {
        Negocio: item.name,
        Plan: item.subscriptionPlan.name,
        Estado: item.status,
      },
    });
  });

  const searching = {
    placeholder: "Buscar negocio",
    action: (search: string) => setFilter({ ...filter, search }),
  };

  const actions: ItemsMultiActionBtn[] = [
    {
      icon: <PlusIcon className="h-5 text-gray-600" />,
      action: () => setModalState(true),
      title: "Nuevo negocio",
    },
  ];

  const rowAction = (id: number) => redirect(id.toString() + "/details");

  //--------------------------------------------------------------------------------

  return (
    <>
      <GenericTable
        tableData={tableData}
        tableTitles={tableTitles}
        searching={searching}
        loading={isLoading}
        actions={actions}
        rowAction={rowAction}
        paginateComponent={
          <Paginate
            action={(page: number) => setFilter({ ...filter, page })}
            data={paginate}
          />
        }
      />
      {modalState && (
        <FormModal
          state={modalState}
          component={
            <BusinessForm
              closeModal={switchModalState}
              action={addBusiness}
              isFetching={isFetching}
              checkField={checkField}
            />
          }
        />
      )}
    </>
  );
};

export default Business;
