import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/root";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { MainRoute } from "./routes/MainRoute";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <MainRoute /> 
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
