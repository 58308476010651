import { CreditCardIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

interface BCInterface{
  name?:string
}

export default function Example({name}:BCInterface) {

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex space-x-4 rounded-md bg-gray-100 px-6 shadow-md">
        <li className="flex">
          <div className="flex items-center">
            <Link to="/business" className="text-gray-400 hover:text-gray-500">
              <CreditCardIcon className='h-6' aria-label='false'/>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-400"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <div
                 className="ml-4 text-lg font-medium text-gray-500 hover:text-gray-700"
                aria-current={true}
              >
                {name}
              </div>
            </div>
          </li>
      </ol>
    </nav>
  )
}
